import React, { useState } from 'react'
import {
  Box,
  Center,
  Flex,
  HStack,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  SliderMark,
  SliderThumb,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import useSWR from 'swr'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faUser, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'

import { fetcher } from '../controllers/contentful'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Title from '../components/Title'
import Button from '../components/Button'
import DateTypeSelect from '../components/DateTypeSelect'

const CARDS = ['freePlan', 'starterPlan', 'proPlan', 'customPlan']
const OPTIONS = {
  renderMark: {
    [MARKS.BOLD]: text => (
      <Text as="span" fontWeight="bold">
        {text}
      </Text>
    )
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => <Text>{children}</Text>,
    [BLOCKS.UL_LIST]: (_node, children) => <Box>{children}</Box>,
    [BLOCKS.LIST_ITEM]: (_node, children) => {
      return (
        <HStack pb="2" spacing="4">
          <Center rounded="full" bg="teal.100" boxSize={4} color="blue.700" w="4" h="4" flexShrink={0}>
            <FontAwesomeIcon icon={faCheck} fontSize="0.5em" />
          </Center>
          <Text
            as="span"
            color="blue.800"
            fontWeight="normal"
            sx={{
              textDecorationLine: 'none'
            }}
          >
            {children}
          </Text>
        </HStack>
      )
    }
  }
}

const TitleRow = ({ children }) => (
  <Text fontWeight="bold" color="gray.800" fontSize="xl">
    {children}
  </Text>
)

const PriceRow = ({ children }) => (
  <Text color="teal.400" fontWeight={700} fontSize="2xl">
    {children}
  </Text>
)

const DescriptionRow = ({ children }) => (
  <Text color="teal.400" fontSize="sm">
    {children}
  </Text>
)

const PricingPage = () => {
  const [selectedPrice, setSelectedPrice] = useState(1)
  const [isYearType, setIsYearType] = useState(true)
  const { data, error } = useSWR('pagePricing', () => fetcher('pagePricing'))

  if (error) {
    console.log('Error while loading Contentful data: ' + error)
  }
  if (!data) return <Spinner size="large" />

  const getLimitValue = (limits, type) => {
    switch (limits.type) {
      case 'single': {
        const v = _.get(limits, type)
        const text = type === 'tours' ? (v === 1 ? 'tour per month' : 'tours per month') : v === 1 ? 'user' : 'users'
        return `${v} ${text}`
      }
      case 'range': {
        const v = _.get(limits, [type, selectedPrice])
        const text = type === 'tours' ? (v === 1 ? 'tour per month' : 'tours per month') : v === 1 ? 'user' : 'users'
        return `${v} ${text}`
      }
      case 'custom': {
        const v = _.get(limits, type)
        const text = type === 'tours' ? (v === 1 ? 'tour per month' : 'tours per month') : v === 1 ? 'user' : 'users'
        return `${v} ${text}`
      }
    }
  }

  const renderPrice = price => {
    switch (price.type) {
      case 'number': {
        return (
          <VStack align="start" justify="center" h="full">
            <PriceRow>{`${price.value}/mo`}</PriceRow>
            <DescriptionRow>{price.description}</DescriptionRow>
          </VStack>
        )
      }
      case 'range': {
        return (
          <VStack align="start" justify="center" h="full">
            <PriceRow>{`$${_.get(price, ['values', selectedPrice])}/mo`}</PriceRow>
            <DescriptionRow>{_.get(price, ['description', selectedPrice])}</DescriptionRow>
            <Slider
              value={selectedPrice}
              onChange={v => setSelectedPrice(v)}
              min={0}
              step={1}
              max={_.size(_.get(price, 'values', [])) - 1}
              colorScheme="teal"
            >
              <SliderTrack h={1.5} bg="gray.200">
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb w={5} h={5} borderWidth={2} borderColor="teal.400" />
              {_.map(_.get(price, 'values'), (p, i) => {
                return (
                  <SliderMark value={i} fontSize="2xs" key={i} pt={4} position="relative">
                    <Text
                      fontWeight="semibold"
                      color={selectedPrice >= i ? 'gray.800' : 'gray.500'}
                      textAlign="center"
                      pointerEvents="all"
                      transform="translate(-50%, 0%)"
                      onClick={() => setSelectedPrice(i)}
                    >
                      ${p}
                    </Text>
                  </SliderMark>
                )
              })}
            </Slider>
          </VStack>
        )
      }
      case 'custom': {
        return (
          <VStack align="start" justify="center" h="full">
            <PriceRow>{price.value}</PriceRow>
          </VStack>
        )
      }
    }
  }

  const renderLimits = (limits, index) => {
    return (
      <VStack key={`limit-${index}`} align="start" justify="center">
        <HStack spacing="4">
          <Flex w="18px" h="24px" justify="center" align="center">
            <FontAwesomeIcon icon={faHouse} />
          </Flex>
          <Text fontSize="sm">{getLimitValue(limits, 'tours')}</Text>
        </HStack>
        <HStack spacing="4">
          <Flex w="18px" h="24px" justify="center" align="center">
            <FontAwesomeIcon icon={faUser} />
          </Flex>
          <Text fontSize="sm">{getLimitValue(limits, 'users')}</Text>
        </HStack>
      </VStack>
    )
  }

  const renderFeatures = features => {
    if (_.isNil(features)) return null
    const text = documentToReactComponents(features, OPTIONS)
    return (
      <Box
        sx={{
          '> *': {
            paddingBottom: '4'
          }
        }}
      >
        {text}
      </Box>
    )
  }

  const renderLink = plan => {
    const href = _.includes(['Pro', 'Starter'], plan.internalName)
      ? plan.internalName === 'Pro'
        ? `${plan.submitButton.description}${_.get(plan, [
            'price',
            isYearType ? 'yearly' : 'monthly',
            'ids',
            selectedPrice
          ])}`
        : `${plan.submitButton.description}${_.get(plan, ['price', isYearType ? 'yearly' : 'monthly', 'id'])}`
      : plan.submitButton.description
    return <Button as="a" href={href} value={plan.submitButton} w="full" />
  }

  const renderPlan = (plan, index) => {
    const price = _.get(plan, ['price', isYearType ? 'yearly' : 'monthly'])
    return (
      <VStack
        key={plan.internalName}
        w="full"
        borderWidth="medium"
        borderStyle="solid"
        borderColor="transparent"
        borderRadius="xl"
        backgroundColor="white"
        boxShadow="md"
        px="8"
        py="12"
        align="start"
        spacing="6"
        sx={{
          transition: 'border 500ms ease-out',
          ':hover': {
            borderColor: 'teal.400'
          }
        }}
      >
        <Box w="full" h="8">
          <TitleRow>{plan.internalName}</TitleRow>
        </Box>
        <Box w="full" h="28">
          {renderPrice(price)}
        </Box>
        <Box w="full" h="16">
          {renderLimits(plan.toursAndUsers, index)}
        </Box>
        <Box w="full" h="20">
          {renderLink(plan)}
          {plan.submitButton.subtitle && (
            <Text fontSize="sm" color="gray.600" textAlign="center" pt="2">
              {plan.submitButton.subtitle}
            </Text>
          )}
        </Box>
        <Box w="full" h="auto">
          {renderFeatures(plan.features)}
        </Box>
      </VStack>
    )
  }

  return (
    <Layout>
      <Seo title="Pricing" />
      <Box
        height="100%"
        maxW={{
          base: '2xl',
          md: '7xl'
        }}
        mx="auto"
        py="12"
      >
        <VStack spacing={{ lg: '16', base: '24' }} pb={{ lg: '24', base: '8' }}>
          <Title value={data.title} />
          <DateTypeSelect isYearType={isYearType} onChange={v => setIsYearType(v)} />
        </VStack>
        <SimpleGrid
          columns={{
            base: 1,
            lg: 4
          }}
          spacing="12"
        >
          {_.map(CARDS, c => {
            const plan = _.get(data, c)
            return renderPlan(plan)
          })}
        </SimpleGrid>
      </Box>
    </Layout>
  )
}

export default PricingPage
