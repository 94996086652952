import React from 'react'
import {
  Box,
  ButtonGroup,
  Button,
  Flex,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'

const Vector = () => (
  <svg width='38' height='20' viewBox='0 0 38 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1 19H20.7746C29.7357 19 37 11.6319 37 2.54286V1' stroke='#3182CE' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>

)

const ACTIVE_FILTER = 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))'

const SelectButton = ({
  active,
  label,
  onChange,
  v
}) => (
  <Button
    variant='unstyled'
    p='6'
    display='inline-flex'
    onClick={() => onChange(v)}
    borderRadius='full'
    filter={active ? ACTIVE_FILTER : 'none'}
    backgroundColor={active ? 'white' : 'transparent'}
    color={active ? 'blue.500' : 'gray.800'}
    w='32'
  >
    {label}
  </Button>
)

const DateTypeSelect = ({
  isYearType,
  onChange
}) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true
  })
  return (
    <Flex justify='center' position='relative'>
      <ButtonGroup spacing='2' borderRadius='full' backgroundColor='gray.100'>
        <SelectButton label='Monthly' onChange={onChange} active={!isYearType} v={false} />
        <SelectButton label='Yearly' onChange={onChange} active={isYearType} v />
      </ButtonGroup>
      <Flex
        position='absolute'
        top={{ base: '-160%', lg: '-50%' }}
        right={{ base: '10', lg: '-24' }}
        direction='column'
        w='28'
        gap={{ base: '4', lg: '1' }}
      >
        <Text alignSelf={{ base: 'center', lg: 'end' }} color='blue.500' fontWeight='bold' size='sm'>
          Save 30%
        </Text>
        <Box
          transform={isDesktop ? null : 'rotate(90deg)'}
          mx='auto'
        >
          <Vector />
        </Box>
      </Flex>
    </Flex>
  )
}

export default DateTypeSelect
