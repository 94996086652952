import React from 'react'
import { Button } from '@chakra-ui/react'
import _ from 'lodash'

const CustomButton = ({ value, ...rest }) => (
  <Button
    variant={_.get(value, 'variant')}
    colorScheme={_.get(value, 'colorScheme')}
    size='2xl'
    minW='200px'
    height='12'
    px='8'
    {...rest}
  >
    {_.get(value, 'label')}
  </Button>
)

export default CustomButton
